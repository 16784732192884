<!-- 实名认证 -->
<template>
    <div class="realName">
        <van-steps :active="active">
            <van-step>输入身份信息</van-step>
            <van-step>验证结果</van-step>
        </van-steps>
        <main class="">
            <div v-if="active == 0">
                <van-cell-group>
                    <van-field readonly label="证件类型" placeholder="身份证" />

                    <van-field center clearable v-model="form.u_id_card" :error-message="idCardErrorMessage" label="身份证号"
                        placeholder="请输入身份证号"> </van-field>
                    <van-field center clearable v-model="form.u_real_name" :error-message="nameErrorMessage" label="姓名"
                        placeholder="请输入姓名"> </van-field>
                </van-cell-group>
            </div>

            <div v-if="active == 1" class="flexCenter">

                <van-icon name="clock-o" v-if="resultStatus == 0" class="clockIcon" />
                <p v-if="resultStatus == 0">等待认证结果</p>
                <van-icon name="passed" v-if="resultStatus == 1" class="passedIcon" />
                <p v-if="resultStatus == 1">认证通过</p>
                <van-icon name="close" v-if="resultStatus == 2" class="closeIcon" />
                <p v-if="resultStatus == 2">认证失败</p>
            </div>
            <div>
                <van-button type="primary" plain block @click="toNextTep" v-if="active == 0">下一步</van-button>
                <!-- <van-button type="primary" plain block @click="toNextTep" v-if="active == 1">提交</van-button> -->

                <van-button style="margin-top: 5px;" type="info" plain block @click="getFaceCertifyResult"
                    v-if="active == 1">认证结果刷新</van-button>
                <van-button style="margin-top: 5px;" type="primary" plain block @click="toNextTep"
                    v-if="active == 1">完成</van-button>
                <van-button style="margin-top: 5px;" plain block @click="toTopOne" v-if="active == 1">返回上一步</van-button>
            </div>
        </main>
        <van-overlay :show="showOverlay">
            <div class="wrapper" @click.stop>
                <van-loading type="spinner" size="24px" />
            </div>
        </van-overlay>
        <van-dialog v-model="showSetZfb" title="请先配置提现支付宝账号" @confirm="setZFB" :showConfirmButton="false">
            <van-field center clearable v-model="zfbObj.u_ali_realname" :error-message="realnameMsg" label="姓名"
                placeholder="请输入姓名"> </van-field>
            <van-field center clearable v-model="zfbObj.u_ali_account" :error-message="ZFBErrMsg" label="支付宝"
                placeholder="请输入支付宝账号"> </van-field>
            <van-button type="primary" plain block @click="setZFB">提交</van-button>
        </van-dialog>

        <van-dialog v-model="showUrlCode" title="支付宝扫码" :show-confirm-button="false">
            <div class="flexCenter" v-if="showUrlCode">

                <div id="qrcode" ref="qrcode" style="margin: 10px 0"></div>
                <div style="color: red;">请使用支付宝扫码完成实名验证</div>
            </div>
            <van-button style="margin-top: 5px;" type="primary" plain block
                @click="getFaceCertifyResult('pcUrlCode')">验证完成</van-button>
        </van-dialog>
    </div>
</template>

<script>

import IdentityCodeValid from './checkIdCard.js/index'
import QRCode from "qrcodejs2";

export default {
    data() {
        return {
            resultStatus: 0,
            active: 0,
            idCardErrorMessage: '',
            nameErrorMessage: '',
            realnameMsg: '',
            ZFBErrMsg: '',
            showOverlay: false,
            showSetZfb: false,
            form: {
                u_id_card: '',
                u_real_name: '',
            },

            zfbObj: {
                u_ali_account: '',
                u_ali_realname: ''
            },
            showUrlCode: false

        };
    },

    components: {
        QRCode
    },

    mounted() {

        let step = this.getQueryVariable('step')
        if (step == '1') {
            this.getFaceCertifyResult()
            this.active = 1
        }
    },

    methods: {

        toNextTep() {
            switch (this.active) {
                case 0:
                    this.getFaceCertifyUrl()
                    break
                case 1:

                    this.$router.push({
                        name: "personalCenter",
                    });
                    this.submit()
                    break
                // case 2:
                //     this.$router.push({
                //         name: "personalCenter",
                //     });

            }
        },
        toTopOne() {
            this.active = this.active - 1
        },


        async getFaceCertifyUrl() {

            if (IdentityCodeValid(this.form.u_id_card) === false) {
                this.idCardErrorMessage = '身份证号不合法'
                return false;
            }
            this.idCardErrorMessage = ''
            if (!this.form.u_real_name) {
                this.nameErrorMessage = '姓名'
                return false
            }
            this.nameErrorMessage = ''
            this.showOverlay = true
            let res = await this.$axios.post('/api/user/getFaceCertifyUrl', this.form)
            this.showOverlay = false
            if (res.code === '000') {
                this.active = 1
                if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
                    window.location.href = res.data.certify_url
                } else {
                    this.showUrlCode = true
                    this.$nextTick(() => {
                        this.generateQRCode(res.data.certify_url)
                    })
                }


            } else if (res.code == '186') {
                this.showSetZfb = true

            } else {
                this.$toast.fail(res.msg)
            }

        },
        setZFB() {
            // 清除支付宝中的空格
            this.zfbObj.u_ali_account = this.zfbObj.u_ali_account.replace(/\s*/g, "");
            if (
                /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/.test(
                    this.zfbObj.u_ali_account
                )
            ) {
                this.ZFBErrMsg = "";
            } else {
                this.ZFBErrMsg = "支付宝账号格式错误";
                return false;
            }
            // 去除真实姓名中的空格
            this.zfbObj.u_ali_realname = this.zfbObj.u_ali_realname.replace(/\s*/g, "");

            if (
                /^[\u4e00-\u9fa5]{1,6}(·[\u4e00-\u9fa5]{1,6}){0,2}$/.test(
                    this.zfbObj.u_ali_realname
                )
            ) {
                this.realnameMsg = "";
            } else {
                this.realnameMsg = "支付宝真实姓名必须全中文";
                return false;
            }
            this.$axios
                .post("/api/user/updateInfo", this.zfbObj)
                .then((res) => {
                    if (res.code == "000") {
                        this.$toast({
                            type: "success",
                            message: "配置成功",
                        });
                        this.showSetZfb = false
                    } else {
                        this.$toast.fail(res.msg)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async getFaceCertifyResult(type) {

            let res = await this.$axios.post('/api/user/faceCertifyQuery')
            if (res.code == '000' || res.code == '163') {
                if (type == 'pcUrlCode') {
                    this.showUrlCode = false
                }
                this.resultStatus = 1
            } else {
                if (type == 'pcUrlCode') {
                    this.$toast.fail('请先完成认证')
                }
                this.resultStatus = 2
            }
            if (type == 'pcUrlCode') {
                this.active = 1
            }
        },
        getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");

                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        generateQRCode(url) {

            new QRCode('qrcode', {
                width: 270,
                height: 270,
                text: url
            });
        }

    }
}

</script>
<style scoped lang="scss">
.realName {
    margin: 60px 20px;
}

.uploadImg {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
    margin: 10px 0;
    border-radius: 5px;
}

.passedIcon {
    font-size: 80px;
    color: #059e4e;
}

.clockIcon {
    font-size: 80px;
    color: #0988f0;
}

.closeIcon {
    font-size: 80px;
    color: #f30606;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.flexCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.jumpZfb {
    display: none;
}
</style>